import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormNumberInput from '../../../views/custom-quote/components/FormNumberInput'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'

const Totalunits = () => {
  const { formData, updateFormData} = useContext(CustomQuoteContext)

  const handleQuantityChange = value => {
    updateFormData({
      ...formData,
      totalUnits: value,
    })
  }

  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.totalUnits.next)
  }

  
  return (
    <CustomQuoteLayout>
      <FormQuestion title="How many pieces?" />
      <FormNumberInput
        onQuantityChange={handleQuantityChange}
        value={formData && formData.totalUnits}
      />
      <FormNavigation
        onSubmit={handleSubmit}
        showBackButton
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.totalUnits.previous}
      />
    </CustomQuoteLayout>
  )
}

export default Totalunits