import React from 'react'
import { css } from 'styled-components'

import { palette, space, fontSizes } from '../../../utils/tokens'

const FormNumberInput = ({
  onQuantityChange,
  value = 1,
  minimumValue
}) => {
  
  const handleQuantityChange = (e) => {
    if(!e.target.value) {
      onQuantityChange(e.target.value)
    } else {
      const int = parseInt(e.target.value)
      if (int < 0 || int === 0) {
        onQuantityChange(1)
      } else {
        onQuantityChange(int)
      }
    }
  }

  return (
    <div>
      <div
        css={css({
          display: `flex`,
          alignItems: `center`,
        })}
      >
        <label
          css={css({
            fontSize: fontSizes[3],
            fontWeight: `500`,
            marginRight: `${space[3]}px`,
          })}
        >
          Quantity
        </label>
        <input
          css={css`
            border-radius: 2px;
            border: 1px solid ${palette.grey[40]};
            width: 64px;
            height: 32px;
            font-weight: bold;
            text-align: center;
            ::-webkit-outer-spin-button,
            ::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
          type="number"
          value={value}
          min={1}
          max={500}
          onChange={handleQuantityChange}
        />
      </div>
    </div>
  )
}

export default FormNumberInput